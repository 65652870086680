import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts";
import { Hero, ContentSection, StateProvider } from "../components";

const IndexPage = ({ data, ...props }) => {
  const { frontmatter: meta, html } = data.markdownRemark;
  return (
    <Layout>
      <StateProvider
        render={props => {
          return (
            <React.Fragment>
              <Hero {...props} />
              <ContentSection {...props} meta={meta} html={html} />
            </React.Fragment>
          );
        }}
      />
    </Layout>
  );
};

export default IndexPage;

export const howQuery = graphql`
  query HowSection {
    markdownRemark(frontmatter: { id: { eq: "how-section" } }) {
      frontmatter {
        title
        subtitle
        id
      }
      html
    }
  }
`;
